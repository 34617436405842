<template>
  <div
    :class="[
      $style.heading,
      { [$style.left]: align === 'left' },
      { [$style.main]: variant === 'main' },
      { [$style.sub]: variant === 'sub' },
    ]"
  >
    <p v-if="sanitizedSubTitle" :style="subTitleCustomStyle" v-html="sanitizedSubTitle"></p>
    <div v-if="variant === 'sub'" :class="{ [$style.line]: sanitizedTitle && sanitizedSubTitle }" />
    <h1 v-if="variant === 'main'" :style="titleCustomStyle" v-html="sanitizedTitle"></h1>
    <h2 v-else-if="variant === 'sub'" :style="titleCustomStyle" v-html="sanitizedTitle"></h2>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { sanitizeHtmlContent } from "@tential/ec-gql-schema/utils/functions/sanitize"
import { type MoleculesConsumerHeadingProps } from "@tential/consumer-component/type"

const props = withDefaults(defineProps<MoleculesConsumerHeadingProps>(), {
  subTitle: undefined,
  align: "center",
  variant: "main",
  titleFontSizePx: undefined,
  subTitleFontSizePx: undefined,
})

const titleCustomStyle = computed(() => {
  if (!props.titleFontSizePx) return {}
  return {
    "font-size": `${props.titleFontSizePx}px`,
  }
})

const subTitleCustomStyle = computed(() => {
  if (!props.subTitleFontSizePx) return {}
  return {
    "font-size": `${props.subTitleFontSizePx}px`,
  }
})

const sanitizedTitle = computed(() => sanitizeHtmlContent(props.title))
const sanitizedSubTitle = computed(() => sanitizeHtmlContent(props.subTitle))
</script>

<style module scoped lang="scss">
.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  &.main {
    flex-direction: column;
    gap: 0.5rem;
    padding: 3.75rem 1rem 2.5rem;
    @include md {
      gap: 0.75rem;
      padding: 8.75rem 1rem 4rem;
    }
    &.left {
      align-items: flex-start;
      padding-left: 0;
    }
    p {
      font-size: $font-size-12;
      @include md {
        font-size: $font-size-15;
      }
    }
    h1 {
      font-size: $font-size-28;
      @include md {
        font-size: $font-size-40;
      }
    }
  }
  &.sub {
    flex-direction: row-reverse;
    gap: 1rem;
    @include md {
      gap: 1.5rem;
    }
    .line {
      display: none;
      @include md {
        display: block;
        height: 30px;
        width: 1px;
        background-color: $primary;
      }
    }
    &.left {
      align-items: center;
      justify-content: flex-end;
      padding-left: 0;
    }
    p {
      font-size: $font-size-12;
      @include md {
        font-size: $font-size-15;
      }
    }
    h2 {
      font-size: $font-size-24;
      @include md {
        font-size: $font-size-32;
      }
    }
  }
}
</style>
